import React from 'react';
import CookieConsent from "react-cookie-consent";

import classes from './ConsentCookie.module.scss';

const ConsentCookie = (props) => (
    <CookieConsent
        location="bottom"
        enableDeclineButton
        flipButtons
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName={props.name}
        containerClasses={classes.CookieConsent}
        contentClasses={classes.CookieContent}
        buttonWrapperClasses={classes.Button}
        buttonClasses={classes.Accept}
        declineButtonClasses={classes.Decline}
        overlay
        expires={150}
    >
        We use cookies on our website to do some totally legit stuff. 
        <div>By accepting, you agree to our use of such cookies.</div>
    </CookieConsent>
)

export default ConsentCookie
