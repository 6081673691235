import React, { useState, useLayoutEffect } from 'react';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Footer from '../../components/UI/Footer/Footer';
import ConsentCookie from '../../components/UI/ConsentCookie/ConsentCookie';

const Layout = props => {

    const [showSideDrawer, toggleSideDrawer] = useState(false);
    const [compress, setCompress] = useState(false);
    const [scrollP, setScrollP] = useState(0);

    useLayoutEffect(() => {
        const handleScroll = e => {
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const height = scrollHeight - clientHeight;

            setScrollP((window.scrollY / height) * 100);
            setCompress(window.scrollY > 50);
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, []);

    return (
        <>
            <Toolbar
                compress={compress}
                scroll={scrollP}
                sideDrawerOpen={showSideDrawer}
                drawerToggleClicked={() => toggleSideDrawer(!showSideDrawer)} />
            <SideDrawer
                open={showSideDrawer}
                closed={() => toggleSideDrawer(!showSideDrawer)} />
            <main>
                {props.children}
            </main>
            <footer>
                <Footer />
            </footer>
            <ConsentCookie
                name="Irithmetic_ConsentCookie"
            />
        </>
    );
}

export default Layout
