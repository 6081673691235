import React from 'react';

import classes from './Toolbar.module.scss';
import Logo from 'components/Logo/Logo';
import NavigationItems from 'components/Navigation/NavigationItems/NavigationItems';
import DrawerToggle from 'components/Navigation/SideDrawer/DrawerToggle/DrawerToggle';
import ScrollIndicator from 'components/UI/ScrollIndicator/ScrollIndicator';

const Toolbar = (props) => {

    let headerClasses = [classes.Toolbar];
    if (props.compress) {
        headerClasses.push(classes.Small);
    } else {
        headerClasses.push(classes.Big);
    }

    return (
        <div className={headerClasses.join(' ')}>
            <header>
                <div className={classes.Logo}>
                    <Logo compress={props.compress} full />
                </div>
                <nav className={classes.DesktopOnly}>
                    <NavigationItems />
                </nav>
                <DrawerToggle
                    close={props.sideDrawerOpen}
                    clicked={props.drawerToggleClicked} />
            </header>
            <ScrollIndicator scroll={props.scroll} />
        </div>
    );
}

export default Toolbar;