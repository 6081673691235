import React from 'react';
import classes from './ScrollIndicator.module.scss';

const ScrollIndicator = (props) => {
    return (
        <div className={classes.ProgressContainer}>
            <div className={classes.ProgressBar} style={{
                width: props.scroll + "%"
            }}></div>
        </div>
    )
}

export default ScrollIndicator
