import React from 'react';
import classes from './Footer.module.scss';
import NavigationItems from 'components/Navigation/NavigationItems/NavigationItems';

import { socialLinks } from 'data/links';
import Author from 'components/UI/Author/Author';
import Copyright from 'components/UI/Copyright/Copyright';

const Footer = () => {
    const links = socialLinks.map((item, index) => (
        <li key={index} onClick={() => { window.location = item.location }}>
            <item.icon size={item.size}/>
        </li>
    ));

    return (
        <section className={classes.Footer}>
            <div className={classes.Container}>
                <div className={classes.Contact}>
                    <h1>Feel the need to Contact Us?</h1>
                    <p> Send us a message and we will get back to you... </p>
                </div>
                <div className={classes.LineWrapper}>
                    <NavigationItems footer />
                </div>
                <div className={classes.LineWrapper}>
                    <Copyright />
                    <ul className={classes.Links}>
                        {links}
                    </ul>
                    <Author />
                </div>
            </div>
        </section>
    )
}

export default Footer
