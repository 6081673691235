import React from 'react';

import classes from './Author.module.scss';

const Author = () => {
    return (
        <div className={classes.Author}>
            Created by&nbsp;
            <a href="https://www.ideonvn.co.za/" >
                Ideon van Niekerk
            </a>
        </div>
    )
}

export default Author
