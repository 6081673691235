import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.scss';

const sideDrawer = (props) => {

    const mainClasses = [classes.SideDrawer];
    if(props.open) {
        mainClasses.push(classes.Open);
    }

    return (
        <>
            <div className={mainClasses.join(' ')} onClick={props.closed}>
                <div className={classes.SideDrawerNav}>
                    <nav>
                        <NavigationItems />
                    </nav>
                </div>
            </div>
        </>
    );
};

export default sideDrawer;