import React from 'react';
import logoSvgCSS from './LogoSvg.module.scss';

export const LogoSvg = (props) => {
    const classes = [logoSvgCSS.Svg];
    if(props.small) {
        classes.push(logoSvgCSS.Small);
    } else if(props.tiny) {
        classes.push(logoSvgCSS.Tiny);
    } else {
        classes.push(logoSvgCSS.Normal);
    }

    return (
        <svg className={classes.join(' ')}>
            <path
                id="middleI"
                d="
                    M100 10
                    L100 50
                    L110 50
                    L130 30
                    L160 30
                    L160 180
                    L130 180
                    L110 160
                    L100 160
                    L100 200
                    L250 200
                    L250 180
                    L250 160
                    L240 160
                    L220 180
                    L190 180
                    L190 30
                    L220 30
                    L240 50
                    L250 50
                    L250 10
                    L100 10
                    Z
                "
            />
            <path
                id="leftBracket"
                d="
                    M80 10
                    L10 100
                    L80 200
                    L85 200
                    L40 100
                    L85 10
                    Z
                "
            />
            <path
                id="rightBracket"
                d="
                    M270 10
                    L340 100
                    L270 200
                    L265 200
                    L310 100
                    L265 10
                    Z
                "
            />
            {props.children}
        </svg>
    )
}
