import React from 'react';
import Logo from 'components/Logo/Logo';

import classes from './Copyright.module.scss';


const Copyright = () => {
    return (
        <div className={classes.Copright}>
            <div className={classes.Logo}>
                <Logo />
            </div>
            Irithmetic &copy; {new Date().getFullYear()}
        </div>
    )
}

export default Copyright
