import React from 'react';
import SiteLogo from 'assets/images/logo.png';
import classes from './Logo.module.scss';
import { LogoSvg } from './LogoSvg';

const logo = (props) => {

    let logoSpan = null;
    if(props.full) {
        let spanClasses = [];
        if (props.compress) {
            spanClasses.push(classes.Small);
        } else {
            spanClasses.push(classes.Big);
        }
        logoSpan = <span className={spanClasses}> Irithmetic </span>;
    }
    
    return (
        <div className={classes.Logo} style={{height: props.height}}>
            <a href="/">
                <LogoSvg small={props.compress} tiny={!props.full}>
                    <img src={SiteLogo} width="85px" height="64px" alt="irithmetic logo" />
                </LogoSvg>
                {logoSpan}
            </a>
        </div>
    );
};

export default logo;