import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from 'components/UI/Spinner/Spinner';
import Layout from 'hoc/Layout/Layout';
import { routeData } from 'data/routes';
import 'App.scss';

function App() {

    const routes = routeData.map((item, index) => (
        <Route key={index} path={item.path} exact component={item.comp} />
    ));

    return (
        <Suspense fallback={<Spinner />}>
            <Layout>
                <Switch>
                    {routes}
                    <Redirect to="/" />
                </Switch>
            </Layout>
        </Suspense>
    );
}

export default App;
