import { lazy } from 'react';

const Home = lazy(() => import('../containers/Home/Home'));
const About = lazy(() => import('../containers/About/About'));
const Services = lazy(() => import('../containers/Services/Services'));
const Contact = lazy(() => import('../containers/Contact/Contact'));

export const routeData = [
    {
        text: "Home",
        path: "/",
        comp: Home,
        main: true,
        footer: false
    },
    {
        text: "About",
        path: "/about",
        comp: About,
        main: false,
        footer: true
    },
    {
        text: "Services",
        path: "/services",
        comp: Services,
        main: true,
        footer: false
    },
    {
        text: "Contact",
        path: "/contact",
        comp: Contact,
        main: false,
        footer: true
    }
];
