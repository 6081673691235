import React from 'react';

import classes from './DrawerToggle.module.scss';

const drawerToggle = (props) => {

    let closeClass = null;
    if (props.close) {
        closeClass= classes.Close;
    }

    return (
        <div className={classes.DrawerToggle} onClick={props.clicked}>
            <div className={[classes.FirstBar, closeClass].join(' ')}></div>
            <div className={[classes.SecondBar, closeClass].join(' ')}></div>
            <div className={[classes.ThirdBar, closeClass].join(' ')}></div>
        </div>
    );
};

export default drawerToggle;